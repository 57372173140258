import { handleActions } from 'redux-actions';
import { NAMES as GET_VIDEO_CARDS } from '../../actions/video-cards/get';
import { VIDEO_CARDS_CLEANUP } from '../../actions/video-cards/cleanup';
import { getResizedImageUrl } from '@wix/wix-vod-shared/dist/src/common/utils/get-resized-image-url';
import { VIDEO_CARD_TYPES } from '@wix/wix-vod-constants/dist/video-cards/types';
import { t } from '../../../utils/translation';
import { ItemType } from '../../types';

const defaultState = [];

const THUMBNAIL_SIZE = 292;

export default handleActions(
  {
    [GET_VIDEO_CARDS.SUCCESS]: (cards, { payload }) => {
      const data = payload.response;
      return data.map((card) => {
        if (card.dateStartLive) {
          const datetime = new Date(card.dateStartLive).toLocaleTimeString([], {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
          });

          card.liveLabel = `${t('widget.video-cards.live-label')}: ${datetime}`;
        }

        if (
          card.type === VIDEO_CARD_TYPES.VIDEO ||
          card.type === VIDEO_CARD_TYPES.RECENT_VIDEO
        ) {
          if (card.itemType === ItemType.ItemLive) {
            card.buttonLabel = t('widget.video-cards.watch-live');
          } else {
            card.buttonLabel = t('widget.video-cards.watch-next');
          }
        }

        if (card.type === VIDEO_CARD_TYPES.CHANNEL) {
          card.buttonLabel = t('widget.video-cards.watch-more');
        }

        return {
          ...card,
          thumbnailUrl: getResizedImageUrl({
            url: card.thumbnailUrl,
            width: THUMBNAIL_SIZE,
            height: THUMBNAIL_SIZE,
            params: { lg: 1 },
          }),
        };
      });
    },
    [VIDEO_CARDS_CLEANUP]: () => defaultState,
  },

  defaultState,
);
