import { noop } from 'lodash';
import { createWorkerHandler } from './createHandler';
import { createFedopsHandler } from './handlers/fedopsLogger';
import { create } from '@wix/fedops-logger';

export function createWorkerHandlerForOOI(
  controllerConfig,
  { isSlave, debug },
  flowAPI,
) {
  const { compId, platformAPIs, wixCodeApi } = controllerConfig;
  const { pubSub } = platformAPIs;
  const fedopsHandler = createFedopsHandler({
    loggerFactory: create,
    pubSub,
    compId,
    baseLoggerName: 'wix-vod-widget',
    isSlave,
  });

  const setNewDimensions = (width, height) =>
    controllerConfig.setProps({
      dimensions: {
        width,
        height,
      },
    });

  return createWorkerHandler(
    controllerConfig,
    {
      performanceApplicationLoadedHandler: () => {
        // https://bo.wix.com/pages/yoshi/docs/editor-flow/runtime-api/fedops#widget-and-settings
        // Events appLoadStarted and appLoaded are logged automatically by the editor flow.
      },
      fedopsHandler,
      handleSetWidgetHeight: (height, width) => setNewDimensions(width, height),
      handleFitIntoView: noop,
      handleResizeWindow: setNewDimensions,
      handleResizeComponent: ({ width, height }) =>
        setNewDimensions(width, height),
      handleLogin: async ({ mode, language }, onLogin, onError) => {
        try {
          const user = await wixCodeApi.user.promptLogin({ mode, language });

          onLogin({
            id: user.id,
            email: await wixCodeApi.user.currentUser.getEmail(),
          });
        } catch (error) {
          onError(error);
        }
      },
      handleLogout: wixCodeApi.user.logout,
      handleGoToPricingPlanPicker: () => {},
    },
    debug,
    flowAPI,
  );
}
